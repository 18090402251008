var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Fazendas")]),_c('v-card-text',[_c('crud-list',{attrs:{"headers":_vm.headers,"slots":[
              'item.status',
              'item.startDate',
              'item.leaveDate',
              'item.endDate',
              'item.substituteReason',
            ],"actions":_vm.actions},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","rounded":"","small":"","plain":"","color":item.status == 1 ? 'green' : 'red'}},[_vm._v(_vm._s(item.status == 1 ? 'Ativo' : 'Inativo'))])]}},{key:`item.startDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.startDate ? _vm.moment(item.startDate).format('DD/MM/YYYY') : '')+" ")]}},{key:`item.leaveDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.leaveDate ? _vm.moment(item.leaveDate).format('DD/MM/YYYY') : '')+" ")]}},{key:`item.endDate`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.endDate ? _vm.moment(item.endDate).format('DD/MM/YYYY') : '')+" ")]}},{key:`item.substituteReason`,fn:function({ item }){return [_c('ShowMore',{attrs:{"text":item.substituteReason ? item.substituteReason : ''}})]}}],null,true),model:{value:(_vm.projects),callback:function ($$v) {_vm.projects=$$v},expression:"projects"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"plain":"","text":"","color":"red"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Fechar")])],1)],1)]}}])}),(_vm.formId)?_c('Form',{attrs:{"id":_vm.formId,"fazendas-project":_vm.fazendasProject},on:{"update":$event => (_vm.projects = $event)},model:{value:(_vm.dialogForm),callback:function ($$v) {_vm.dialogForm=$$v},expression:"dialogForm"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }