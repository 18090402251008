<template>
  <v-card class="mx-2">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="projects"
      :headers="headers"
      :slots="[
        'item.status',
        'item.startProject',
        'item.endProject',
        'item.fazendas_project',
        'item.partial1',
        'item.partial2',
        'item.finalProject',
      ]"
      :actions="actions"
    >
      <template v-if="agroindustry?.codAgroindustria" v-slot:btnCadastro>
        <v-btn color="success" @click="newProject"> Novo projeto </v-btn>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-btn
          text
          rounded
          small
          plain
          :class="ProjectStatusClassColors[item.status]"
          >{{ ProjectStatusText[item.status] }}</v-btn
        >
      </template>

      <template v-slot:[`item.startProject`]="{ item }">
        {{ moment(item.startProject).format('DD/MM/YYYY') }}
      </template>

      <template v-slot:[`item.endProject`]="{ item }">
        {{ moment(item.endProject).format('DD/MM/YYYY') }}
      </template>

      <template v-slot:[`item.partial1`]="{ item }">
        {{ moment(item.partial1).format('DD/MM/YYYY') }}
      </template>

      <template v-slot:[`item.partial2`]="{ item }">
        {{ moment(item.partial2).format('DD/MM/YYYY') }}
      </template>

      <template v-slot:[`item.finalProject`]="{ item }">
        {{ moment(item.finalProject).format('DD/MM/YYYY') }}
      </template>

      <template v-slot:[`item.fazendas_project`]="{ item }">
        <span
          style="display: flex; align-items: center; justify-content: center"
        >
          <span>{{ item.fazendas_project.length }}</span>
          <FazendasDialog :fazendas-project="item.fazendas_project" />
        </span>
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import moment from 'moment'
import projectService from '../../services/api/project'
import {
  ProjectStatus,
  ProjectStatusClassColors,
  ProjectStatusText,
} from '../../utils/enums/ProjectStatus'
import FazendasDialog from './FazendasDialog.vue'
export default {
  components: { FazendasDialog },

  props: {
    agroindustry: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      ProjectStatusText,
      ProjectStatus,
      ProjectStatusClassColors,
      loading: false,
      actions: [
        {
          title: 'Editar Projeto',
          color: 'yellow darken-3',
          click: item => {
            this.$router.push({
              path: `/${this.$user.get().role}/project/form`,
              query: {
                id: item.id,
                agroindustry: item.agroindustry,
                name: item.agroindustria.pessoajuridica.NomeFantasia,
              },
            })
          },

          icon: 'mdi-pencil',
        },
        {
          title: 'Excluir Projeto',
          color: 'red darken-3',
          click: item => this.delete(item),
          icon: 'mdi-delete',
        },
        {
          title: 'Dashboard',
          color: 'blue darken-3',
          click: item => {
            this.$router.push({
              path: `/${this.$user.get().role}/projectFarm/dashboard`,
              query: {
                id: item.id,
              },
            })
          },
          icon: 'mdi-view-dashboard',
        },
      ],
      headers: [
        {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '30px',
          filterable: true,
        },
        {
          text: 'Nome do projeto',
          align: 'left',
          sortable: true,
          value: 'name',
          width: 'auto',
        },
        {
          text: 'Data inicial',
          align: 'left',
          sortable: true,
          value: 'startProject',
        },
        {
          text: 'Data final',
          align: 'left',
          sortable: true,
          value: 'endProject',
        },
        {
          text: 'Tempo de Projeto',
          align: 'center',
          value: 'projectTime',
        },
        {
          text: 'Tempo para o término',
          align: 'center',
          value: 'endProjectTime',
        },
        {
          text: 'Fazendas iniciais',
          align: 'left',
          sortable: true,
          width: '20px',
          value: 'initials',
        },
        {
          text: 'Fazendas atuais',
          align: 'left',
          sortable: true,
          width: '20px',
          value: 'current',
        },
        {
          text: 'Parcial 1',
          align: 'left',
          sortable: true,
          value: 'partial1',
        },
        {
          text: 'Parcial 2',
          align: 'left',
          sortable: true,
          value: 'partial2',
        },
        {
          text: 'Final',
          align: 'left',
          sortable: true,
          value: 'finalProject',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Agroindústria',
          value: 'agroindustria.pessoajuridica.NomeFantasia',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Relatório',
          value: 'report.name',
          filterable: true,
        },
        {
          align: 'left',
          sortable: true,
          text: 'Fazendas',
          value: 'fazendas_project',
        },
      ],

      projects: [],

      tabs: 0,
    }
  },

  mounted() {},

  async created() {
    await this.index()
  },

  methods: {
    async index() {
      try {
        this.loading = true
        let { data } = await projectService.index({
          agroindustry: this.agroindustry?.codAgroindustria || null,
        })

        data = data.map(item => {
          let diff = moment(moment()).diff(item.startProject, 'milliseconds')
          let duration = moment.duration(diff)

          const projectTime = duration.years() * 12 + duration.months()

          item.projectTime =
            projectTime + ' meses e ' + duration.days() + ' dias'

          diff = moment(item.endProject).diff(moment(), 'milliseconds')
          duration = moment.duration(diff)

          let endProjectTime = duration.years() * 12 + duration.months()

          if (endProjectTime < 0) {
            item.endProjectTime = 'Projeto finalizado'
          } else {
            item.endProjectTime =
              endProjectTime + ' meses e ' + duration.days() + ' dias'
          }

          const initials = item.fazendas_project.filter(fazenda => {
            if (fazenda.initial === 1) return fazenda
          })

          item.initials = initials.length

          const currentFarms = item.fazendas_project.filter(fazenda => {
            if (fazenda.status === 1) return fazenda
          })

          item.current = currentFarms.length

          return item
        })

        this.projects = data
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os projetos: ' + error.message,
        })
      } finally {
        this.loading = false
      }
    },

    newProject() {
      try {
        this.$router.push({
          path: `/${this.$user.get().role}/project/form`,
          query: {
            agroindustry: this.agroindustry.codAgroindustria,
            name: this.agroindustry.nomeFantasia,
          },
        })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao criar um novo projeto: ' + error.message,
        })
      }
    },

    async delete(item) {
      try {
        this.Swal.fire({
          title: 'Apagar projeto',
          text: 'Você tem certeza que deseja remover este projeto?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff5252',
          cancelButtonColor: '#999',
          confirmButtonText: 'Sim, desejo removê-lo!',
          cancelButtonText: 'Cancelar',
        })
          .then(async result => {
            if (result.isConfirmed) {
              await projectService.delete(item.id)

              this.Toast().fire({
                icon: 'success',
                title: 'Projeto excluído com sucesso!',
              })

              await this.index()
            }
          })
          .catch(error => {
            this.Toast().fire({
              icon: 'error',
              title: 'Erro ao excluir o projeto: ' + error.message,
            })
          })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os projetos: ' + error.message,
        })
      }
    },
  },
}
</script>
