var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-2"},[(_vm.loading)?_c('base-loading'):_c('crud-list',{attrs:{"headers":_vm.headers,"slots":[
      'item.status',
      'item.startProject',
      'item.endProject',
      'item.fazendas_project',
      'item.partial1',
      'item.partial2',
      'item.finalProject',
    ],"actions":_vm.actions},scopedSlots:_vm._u([(_vm.agroindustry?.codAgroindustria)?{key:"btnCadastro",fn:function(){return [_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.newProject}},[_vm._v(" Novo projeto ")])]},proxy:true}:null,{key:`item.status`,fn:function({ item }){return [_c('v-btn',{class:_vm.ProjectStatusClassColors[item.status],attrs:{"text":"","rounded":"","small":"","plain":""}},[_vm._v(_vm._s(_vm.ProjectStatusText[item.status]))])]}},{key:`item.startProject`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.startProject).format('DD/MM/YYYY'))+" ")]}},{key:`item.endProject`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.endProject).format('DD/MM/YYYY'))+" ")]}},{key:`item.partial1`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.partial1).format('DD/MM/YYYY'))+" ")]}},{key:`item.partial2`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.partial2).format('DD/MM/YYYY'))+" ")]}},{key:`item.finalProject`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.moment(item.finalProject).format('DD/MM/YYYY'))+" ")]}},{key:`item.fazendas_project`,fn:function({ item }){return [_c('span',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('span',[_vm._v(_vm._s(item.fazendas_project.length))]),_c('FazendasDialog',{attrs:{"fazendas-project":item.fazendas_project}})],1)]}}],null,true),model:{value:(_vm.projects),callback:function ($$v) {_vm.projects=$$v},expression:"projects"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }