<template>
  <div>
    <v-dialog transition="dialog-top-transition" max-width="90%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon color="primary" v-bind="attrs" v-on="on"
          ><v-icon>mdi-information-outline</v-icon></v-btn
        >
      </template>
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Fazendas</v-toolbar>
          <v-card-text>
            <crud-list
              v-model="projects"
              :headers="headers"
              :slots="[
                'item.status',
                'item.startDate',
                'item.leaveDate',
                'item.endDate',
                'item.substituteReason',
              ]"
              :actions="actions"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-btn
                  text
                  rounded
                  small
                  plain
                  :color="item.status == 1 ? 'green' : 'red'"
                  >{{ item.status == 1 ? 'Ativo' : 'Inativo' }}</v-btn
                >
              </template>
              <template v-slot:[`item.startDate`]="{ item }">
                {{
                  item.startDate
                    ? moment(item.startDate).format('DD/MM/YYYY')
                    : ''
                }}
              </template>
              <template v-slot:[`item.leaveDate`]="{ item }">
                {{
                  item.leaveDate
                    ? moment(item.leaveDate).format('DD/MM/YYYY')
                    : ''
                }}
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                {{
                  item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : ''
                }}
              </template>
              <template v-slot:[`item.substituteReason`]="{ item }">
                <ShowMore
                  :text="item.substituteReason ? item.substituteReason : ''"
                />
              </template>
            </crud-list>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn plain text color="red" @click="dialog.value = false"
              >Fechar</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <Form
      v-if="formId"
      :id="formId"
      v-model="dialogForm"
      :fazendas-project="fazendasProject"
      @update="$event => (projects = $event)"
    />
  </div>
</template>

<script>
import ShowMore from '../../components/base/ShowMore.vue'
import farmProjectService from '../../services/api/farmProject'
import Form from './fazendaProject/form.vue'
export default {
  name: 'FazendasProjectDialog',
  components: { ShowMore, Form },
  props: {
    fazendasProject: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      actions: [
        {
          title: 'Editar Fazenda do projeto',
          color: 'yellow darken-3',
          click: item => {
            this.formId = item.id
            this.dialogForm = !this.dialogForm
          },
          icon: 'mdi-pencil',
        },
        {
          title: 'Inativar fazenda do projeto',
          color: 'red darken-3',
          click: item => this.delete(item),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Status',
          align: 'left',
          sortable: true,
          value: 'status',
          width: 'auto',
        },
        {
          text: 'Fazenda',
          align: 'left',
          sortable: true,
          value: 'fazenda.pessoa.pessoajuridica.NomeFantasia',
          width: 'auto',
        },
        {
          text: 'Produtor',
          align: 'left',
          sortable: true,
          value: 'fazenda.produtor.Nome',
          width: 'auto',
        },
        {
          text: 'Data de inclusão',
          align: 'left',
          sortable: true,
          value: 'startDate',
          width: '100px',
        },
        {
          text: 'Data do afastamento',
          align: 'left',
          sortable: true,
          value: 'leaveDate',
          width: '80px',
        },
        {
          text: 'Data de término',
          align: 'left',
          sortable: true,
          value: 'endDate',
          width: '80px',
        },
        {
          text: 'Fazenda subistituta',
          align: 'left',
          sortable: true,
          value: 'substituto.fazenda.pessoajuridica.NomeFantasia',
        },
        {
          text: 'Motivo da saída',
          align: 'left',
          sortable: true,
          width: '300px',
          value: 'substituteReason',
        },
      ],
      projects: [],

      dialogForm: false,
      formId: '',
    }
  },

  watch: {
    dialogForm(value) {
      if (!value) {
        this.formId = ''
      }
    },
  },

  mounted() {
    this.projects = this.fazendasProject
  },
  methods: {
    async delete(item) {
      try {
        this.Swal.fire({
          title: 'Apagar fazenda do projeto?',
          text: 'Você tem certeza que deseja inativar esta fazenda?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#ff5252',
          cancelButtonColor: '#999',
          confirmButtonText: 'Sim, desejo inativar!',
          cancelButtonText: 'Cancelar',
        })
          .then(async result => {
            if (result.isConfirmed) {
              const result = await farmProjectService.inactivate(item.id)
              this.Toast().fire({
                icon: 'success',
                title: result.message,
              })
              this.projects = this.projects.map(project => {
                if (project.id === item.id) {
                  return {
                    ...project,
                    status: 'Inativo',
                    leaveDate: new Date(),
                    endDate: new Date(),
                  }
                }
                return project
              })
            }
          })
          .catch(error => {
            this.Toast().fire({
              icon: 'error',
              title: 'Erro ao inativar fazenda do projeto: ' + error.message,
            })
          })
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: error.message || 'Erro ao excluir fazenda',
        })
      }
    },
  },
}
</script>
